.Signup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: calc(100vw * 0.25);
    margin-right: calc(100vw * 0.25);
}

.Form{
    font-family: 'Poppins', sans-serif; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.FormInput {
    width: calc(100vw * 0.18); /* Full width of the parent container */
    height: calc(100vh * 0.05);
    padding: 10px 15px; /* Space inside the input */
    margin: 8px 0; /* Space outside the input */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border: 2px solid #ccc; /* Light gray border */
    border-radius: 5px; /* Rounded corners */
    font-size: 20px; /* Font size */
    font-family: Arial, sans-serif; /* Font family */
    transition: border-color 0.3s ease; /* Smooth transition for the border color */
}

.FormInput:focus {
    border-color: #007bff; /* Blue border on focus */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle blue shadow on focus */
}

.FormInput::placeholder {
    color: #888; /* Placeholder text color */
    opacity: 1; /* Full opacity for placeholder */
}

.FirstRow{
    display: flex;
}

.Firstname{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
}

.Lastname{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    margin-left: calc(100vw * 0.05);
}

.SecondRow{
    display: flex;
}

.Email{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
}

.DateOfBirth{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    margin-left: calc(100vw * 0.05);
}

.SignUpButton{
    width: calc(100vw * 0.06);
    height: calc(100vh * 0.05);
    border-radius: 20px;
    color: white;
    background-color: #61dafb;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    margin-top: calc(100vh * 0.04);
}