.input-container {
    margin-top: calc(100vh * 0.02);
    margin-bottom: calc(100vh * 0.02);
    max-width: calc(100vw * 0.68);
}

.styled-input {
    display: flex;
    justify-content: flex-start;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    padding-left: 2px;
    font-size: 1.25rem; 
    border-radius: 5px;
    flex-wrap: wrap;
    align-items: center;
}

.hidden-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    opacity: 0;
    height: 0px;
    box-sizing: border-box;
}

.word {
    color: white;
    font-size: 1.1rem; 
    font-weight: bold;
    padding: 0.5rem 1rem; 
    border-radius: 1rem; 
    background-color: #D2B48C;
    margin-right: 1rem;
}

.caret {
    border-left: 1px solid black;
    height: 20px;
    animation: blink 2s steps(2, start) infinite;
}

@keyframes blink {
to {
    visibility: hidden;
}
}

.placeholder {
    color: rgb(227, 227, 227);
}