/* The overlay itself */
.Overlay {
    position: absolute;
    width: calc(100vw);
    height: calc(100vh);
    background-color: rgba(0, 0, 0, 0.8); /* Black background with transparency */
    z-index: 1000;
  }
  
  /* Content inside the overlay */
  .OverlayContent {
    background-color: white;
    border-radius: 10px;
    position: relative;
    overflow-y: auto;
  }
  
  /* Close button */
  .CloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    margin-right: calc(100vw * 0.01);
  }
  
  /* Styling for the list of images */
  .ImageList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .ImageContainer {
    margin: 10px;
  }
  
  .OverlayImage {
    max-height: calc(100vh * 0.7);
    cursor: pointer;
  }
  