.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Neighborhoods {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) { 
  .Neighborhoods {
    flex-direction: column; /* zet de items onder elkaar */
    align-items: center; /* centreren van de items */
  }
}

.Top {
  display: flex;
  flex-wrap: wrap;
  justify-self: center;
  align-self: center;
}

@media (max-width: 768px) { 
  .Top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.TopLeft {
  width: calc(100vw * 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: calc(100vh * 0.01);
}

.LoginApp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vh * 0.1);
  height: calc(100vh * 0.05);
  border-radius: 20px;
  color: white;
  background-color: #61dafb;  
  font-family: 'Poppins', sans-serif; 
  font-size: 1rem;
  font-weight: bold;
  margin-right: 10px;
  margin-left: calc(100vh * 0.02);
  cursor: pointer;
}

.SignupApp{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vh * 0.1);
  height: calc(100vh * 0.05);
  border-radius: 20px;
  color: white;
  background-color: #61dafb;  
  font-family: 'Poppins', sans-serif; 
  font-size: 1rem;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
}

.TopCenter {
  width: calc(100vw * 0.6);
}

.TopRight {
  width: calc(100vw * 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Button {
  width: calc(100vh * 0.23);
  height: calc(100vh * 0.05);
  border-radius: 20px;
  color: white;
  background-color: #f34545;  
  font-family: 'Poppins', sans-serif; 
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0rem;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ProfileIcon {
  padding: 30px;
}