.Item {
    flex: 1; 
    max-width: calc(100vw / 6);
    padding: 10px;
    margin-bottom: calc(100vh * 0.1);
}

@media (max-width: 768px) { 
    .Item {
        max-width: calc(100vw * 0.8);
    }
}

.Picture {
    border-radius: 5%;
    width: 100%;
    height: 100%;
    object-fit: 'contain';
    width: calc(100vw / 6);
    height: calc(100vh/4.5);
    object-fit: cover;
}

.Picture:hover {
    cursor: pointer;
}

@media (max-width: 768px) { 

    .Picture {
        border-radius: 5%;
        width: 100%;
        height: 100%;
        object-fit: 'contain';
        width: calc(100vw * 0.8);
        height: calc(100vh/4.5);
        object-fit: cover;
    }
}

.Info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Info h2 {
    font-size: 1.5rem; /* Large, noticeable header */
    color: #3498DB; /* Subtle cloudy blue color */
    margin-bottom: 0rem;
}

.LocationNeighborhoods {
    font-size: 1rem; /* Comfortable font size for reading */
    color: #444; /* Slightly muted text color */
    line-height: 0; /* Comfortable line height */
    margin-bottom: 0.75rem;
}

.TypesNeighborhoods {
    display: flex;
    margin-top: 0.1rem;
    flex-wrap: wrap;
}

.TypeNeighborhoods {
    color: white;
    font-size: 1rem; 
    font-weight: bold;
    padding: 0.3rem 0.6rem; 
    border-radius: 1rem; 
    background-color: #D2B48C;
    margin-right: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;    
}

.DescriptionNeighborhoods {
    font-size: 1.25rem; /* Comfortable font size for reading */
    color: #444; /* Slightly muted text color */
    text-align: left;
    margin-bottom: 0.75rem;
    flex-wrap: wrap;
}

.HeartContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: calc(100vh*0.02);
}

.Heart:hover {
    cursor: pointer;
}
