/* .AddNeighborhood{
    display: flex;
} */

.BackArrow {
    display: flex;
    justify-content: flex-start;
}

.FormAddNeighborhood {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Images {
    display: flex;
    align-items: center;
}

.FirstImage{
    display: flex;
    border: 2px solid lightgray;
    width: calc(100vw * 0.45); 
    height: calc(100vh * 0.54);
    margin: calc(100vw * 0.01);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}

.RightColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SecondImage{
    display: flex;
    border: 2px solid lightgray;
    width: calc(100vw * 0.22); 
    height: calc(100vh * 0.26);
    margin: calc(100vw * 0.01);
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.BrowseImages{
    display: flex;
    margin-left: calc(100vw * 0.01);
}

.ImagesPreview{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.ImageDelete {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    transition: background-color 0.1s ease;
    cursor: pointer;
    margin-left: calc(100vw * 0.01);
}
  
.ImageDelete:hover {
    background-color: lightgrey; /* Background color on hover */
}

.ImagePreview{
    width: calc(100vw * 0.02);
    border-radius: 5px;
}

.bin {
    padding-left: 10px;
}

.InputName { 
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 3rem;
    border: none;         
    color: #3498DB;           
    outline: none;   
}

.InputName::placeholder {
    color: lightgrey;   
}

.InputLocation {
    font-family: Poppins, sans-serif;
    font-size: 1rem; 
    color: #444; 
    line-height: 0; 
    margin-bottom: 0.75rem;
    border: none;         
    outline: none;  
    width: calc(100vw * 0.45);
}

.InputLocation::placeholder {
    color: lightgrey; 
    font-weight: 800;
 
}

.InputShortDescription {
    font-family: Poppins, sans-serif;
    font-size: 1.25rem; 
    color: #444; 
    border: none;         
    outline: none;  
    width: calc(100vw * 0.68);
    height: calc(100vh * 0.02);
    margin-top: calc(100vh * 0.01);
}

.InputShortDescription::placeholder {
    color: lightgrey;  
    width: calc(100vw * 0.68);
    font-weight: 800;
}

.InputLongDescription {
    font-family: Poppins, sans-serif;
    font-size: 1.25rem; 
    color: #444; 
    border: none;         
    outline: none;  
    resize: none;
    width: calc(100vw * 0.68);
    height: calc(100vh * 0.2);
}

.InputLongDescription::placeholder {
    color: lightgrey;  
    width: calc(100vw * 0.68);
    height: calc(100vh * 0.2);    
    font-weight: 800;
}

.submit {
    color: white;
    background-color: #3498DB;
    border-radius: 40px;
    font-family: 'Poppins', sans-serif; 
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 20px 10px 20px; 
    margin-top: 20px;
}