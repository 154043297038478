.Arrow {
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.1s ease;
    cursor: pointer;
}
  
.Arrow:hover {
    background-color: lightgrey; /* Background color on hover */
}

.Neighborhood {
    display: flex;
    flex-direction: column;
    width: 'calc(100vw * 0.7)';
    margin-left: calc(100vw * 0.15);
    margin-right: calc(100vw * 0.15);
}

.NeighborhoodImages:hover {
    cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.InfoNeighborhood {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: calc(100vw * 0.01);
    margin-right: calc(100vw * 0.01);
    font-family: 'Poppins', sans-serif; /* Peaceful and modern font */
}

.InfoNeighborhood h1 {
    font-size: 3rem; /* Large, noticeable header */
    color: #3498DB; /* Subtle cloudy blue color */
    margin-bottom: 0rem;
}

.Location {
    font-size: 1.25rem; /* Comfortable font size for reading */
    color: #444; /* Slightly muted text color */
    line-height: 0; /* Comfortable line height */
    margin-bottom: 0.75rem;
}

.Types {
    display: flex;
    margin-top: 1rem;
}

.Type {
    color: white;
    font-size: 1.1rem; 
    font-weight: bold;
    padding: 0.5rem 1rem; 
    border-radius: 1rem; 
    background-color: #D2B48C;
    margin-right: 1rem;
}

.Description {
    font-size: 1.25rem; /* Comfortable font size for reading */
    color: #444; /* Slightly muted text color */
    text-align: left;
    margin-bottom: 0.75rem;
    flex-wrap: wrap;
}

.Footer {
    height: calc(100vh * 0.15);
}